<script>
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";
import FileInput from "../elements/file-input.vue";
import MultipleSelect from "../elements/multiple-select.vue";
import Swal from "sweetalert2";
import BillablesManager from "../elements/billables-manager.vue";

export default defineComponent({
    name: "offer-form",

    components: {BillablesManager, MultipleSelect, FileInput},

    emits: ['saved', 'updated'],

    props: {
        offer: {
            type: Object,
            required: true
        },

        fixed: {
            type: Boolean,
            default: false
        },

        redirect: {
            type: Boolean,
            default: true
        },

        doNotSave: {
            type: Boolean,
            default: false
        },

        showed: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'offer/loading',
            'states': 'offer/states',
            'errors': 'offer/errors',
            projects: 'project/all',
            contracts: 'contract/all',
            project: 'project/item',
            locale: 'trans/locale',
            configuration: 'app/configuration',
            admin: 'auth/admin'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        fileLinkIsRequired: function () {
            return this.configuration?.projects?.required_file_type === 'link';
        },

        fileFileIsRequired: function () {
            return this.configuration?.projects?.required_file_type === 'file';
        },

        closed: function () {
            return this.locked && this.model.assessed;
        },

        projectId: function () {
            return this.model?.project_id || this.project?.id;
        },

        readOnlyAmount: function () {
            return this.model?.billables?.length;
        },
    },

    data: function () {
        return {
            model: {},
            meetings: [],
            locked: true,
        }
    },

    methods: {
        setFile: function (event) {
            this.model.file_file = event.target.files[0]
        },

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = this.model

            if (!this.doNotSave) {
                return this.$store.dispatch(`offer/${method}`, data).then(saved => {
                    this.setModel(saved)
                    this.$emit('saved')
                    if (method === 'create') {
                        this.$router.push(this.$t('routes.'+'/offers/:id(\\d+)', {id: this.model.id}).rmValidation())
                    }
                })
            } else {
                this.$store.dispatch('offer/setItem', this.model)
                this.$emit('updated', this.model)
            }
        },

        setModel: function (model = {}) {
            const base = {}

            if (!model.id) {
                base.sent_at = (new Date()).formattedDate()
            }

            this.model = Object.assign(base, JSON.parse(JSON.stringify(model)));
        },

        fetchMeetings: function () {
            this.$store.dispatch('meeting/byProject', this.projectId).then(response => {
                this.meetings = response;
            });
        },

        answered: function () {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.what_happened').ucFirst(),
                text: this.$t('projects::offers.customer_response').ucFirst(),
                showCancelButton: true,
                showConfirmButton: true,
                showDenyButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-success',
                    denyButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.accepted').ucFirst(),
                denyButtonText: this.$t('base.refused').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.model.assessed = true;
                    this.model.accepted = true;
                    this.save();
                }
                if (result.isDenied) {
                    this.model.assessed = true;
                    this.model.accepted = false;
                    this.save();
                }
            });
        },

        unlock: function () {
            if (this.admin === true) {
                Swal.fire({
                    icon: 'warning',
                    title: this.$t('base.confirm_title'),
                    text: this.$t('base.confirm_message'),
                    showCancelButton: true,
                    showConfirmButton: true,
                    customClass: {
                        icon: 'text-warning border-warning',
                        confirmButton: 'btn btn-warning',
                        cancelButton: 'btn btn-warning'
                    },
                    confirmButtonText: this.$t('base.unlock').ucFirst(),
                    cancelButtonText: this.$t('base.cancel').ucFirst(),
                }).then(result => {
                    if (result.isConfirmed) {
                        this.locked = false;
                    }
                });
            }
        },
    },

    watch: {
        offer: {
            immediate: true,
            deep: true,
            handler: function (value) {
                this.setModel(value);
            },
        },

        projectId: {
            immediate: true,
            handler: function (id) {
                if (id) {
                    this.fetchMeetings();
                }
            },
        }
    },

    created() {
        this.setModel(this.offer)
    }
})
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row">
                <div v-if="model.project_id" class="col-12 mb-4 mb-lg-5">
                    <h3 id="offer-project" class="text-muted border-bottom pb-2 border-secondary">
                        <router-link :to="$t('routes./projects/accessible-projects/:id(\\d+)', {id: model.project_id}).rmValidation()">
                            <i class="fas fa-clipboard-list me-3"></i>
                            <span>{{ model?.project?.name }}</span>
                        </router-link>
                    </h3>
                    <p v-if="model.assessed || model.sent_at">
                        <i v-if="model.accepted" class="fas fa-check text-success me-2"></i>
                        <i v-else-if="model.accepted === false" class="fas fa-times text-danger me-2"></i>
                        <template v-else-if="model.sent_at">
                            <i class="fas fa-mail-bulk text-primary me-2"></i>
                            <small>{{ model.sent_at.printDate(locale) }}</small>
                        </template>
                    </p>
                </div>
                <div class="col-12 col-lg-8">
                    <div class="row">
                        <div class="col-lg-4 mb-4">
                            <label
                                :class="{'text-danger': states.code === false}"
                                class="form-label"
                                for="code"
                            >{{ $t('projects::offers.columns.code').ucFirst() }} *</label
                            >

                            <input
                                id="code"
                                v-model="model.code"
                                :class="{'border-danger': states.code === false}"
                                :placeholder="$t('projects::offers.placeholders.code').ucFirst()"
                                class="form-control"
                                type="text"
                            />
                            <div :class="{'d-block': states.code === false}" class="invalid-feedback">{{ errors.code }}</div>
                        </div>
                        <div class="col-lg-2 mb-4" v-if="!fixed && !model.project_id">
                            <label
                                :class="{'text-danger': states.project_id === false}"
                                class="form-label"
                                for="project_id"
                            >{{ $t('projects::offers.columns.project_id').ucFirst() }}</label>
                            <select
                                id="project_id"
                                v-model="model.project_id"
                                :class="{'border-danger': states.project_id === false}"
                                class="form-select"
                                name="project_id"
                            >
                                <option :value="model.id ? null : undefined">
                                    {{ $t('projects::offers.placeholders.project_id').ucFirst() }}
                                </option>
                                <option v-for="(project, key) in projects" :key="key" :value="project.id">{{
                                        project.translation_key ? $t(project.translation_key).ucFirst() : project.name
                                    }}
                                </option>
                            </select>
                            <div :class="{'d-block': states.project_id === false}" class="invalid-feedback">
                                {{ errors.project_id }}
                            </div>
                        </div>
                        <div v-if="false" class="col-lg-2 mb-4">
                            <label
                                :class="{'text-danger': states.sent_at === false}"
                                class="form-label"
                                for="sent_at"
                            >{{ $t('projects::offers.columns.sent_at').ucFirst() }}</label
                            >
                            <input
                                id="sent_at"
                                v-model="model.sent_at"
                                :class="{'border-danger': states.sent_at === false}"
                                :placeholder="$t('projects::offers.placeholders.sent_at').ucFirst()"
                                class="form-control"
                                type="datetime-local"
                            />
                            <div :class="{'d-block': states.sent_at === false}" class="invalid-feedback">
                                {{ errors.sent_at }}
                            </div>
                        </div>

                        <div class="col-lg-4 mb-4">
                            <label
                                :class="{'text-danger': states.amount === false}"
                                class="form-label"
                                for="amount"
                            >{{ $t('projects::offers.columns.amount').ucFirst() }} *</label
                            >
                            <div class="input-group">
                                <div class="input-group-text">
                                    <i class="fas fa-dollar-sign"></i>
                                </div>
                                <input
                                    id="amount"
                                    v-model="model.amount"
                                    :class="{'border-danger': states.amount === false, 'bg-light': readOnlyAmount}"
                                    :placeholder="$t('projects::offers.placeholders.amount').ucFirst()"
                                    class="form-control"
                                    type="number"
                                    step="any"
                                    :readonly="readOnlyAmount"
                                />
                            </div>
                            <div :class="{'d-block': states.amount === false}" class="invalid-feedback">
                                {{ errors.amount }}
                            </div>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <label
                                :class="{'text-danger': states.expiration === false}"
                                class="form-label"
                                for="expiration"
                            >{{ $t('projects::offers.columns.expiration').ucFirst() }}</label
                            >
                            <input
                                id="expiration"
                                v-model="model.expiration"
                                :class="{'border-danger': states.expiration === false}"
                                :placeholder="$t('projects::offers.placeholders.expiration').ucFirst()"
                                class="form-control"
                                type="datetime-local"
                            />
                            <div :class="{'d-block': states.expiration === false}" class="invalid-feedback">
                                {{ errors.expiration }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <label
                                :class="{'text-danger': states.meetings === false}"
                                class="form-label"
                                for="meetings"
                            >{{ $t('projects::offers.relationships.meetings').ucFirst() }}</label
                            >
                            <multiple-select id="meeting" v-model="model.meetings" :list="meetings" text-field="subject" extra-field="start" :extra-formatter="str => str?.printDate(locale, {dateStyle: 'short'})"></multiple-select>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="row mb-4 pt-4 px-3">
                        <div class="col-12 bg-soft-primary rounded pt-3">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="text-primary">
                                        <i class="fas fa-paperclip"></i>
                                    </h3>
                                </div>
                                <div class="col-12 mb-4" v-if="!fileLinkIsRequired">
                                    <label
                                        :class="{'text-danger': states.file_file === false}"
                                        class="form-label"
                                        for="file_file"
                                    >{{ $t('projects::offers.columns.file_id').ucFirst() }}</label>
                                    <file-input
                                        id="file_file"
                                        name="file_file"
                                        :class="{'border-danger': states.file === false}"
                                        :placeholder="$t('interactions::interactions.interactions.offer').ucFirst()"
                                        @change="setFile($event)"
                                    ></file-input>
                                    <div :class="{'d-block': states.file_file === false}" class="invalid-feedback">
                                        {{ errors.file_file }}
                                    </div>
                                </div>
                                <div class="col-12 mb-4" v-if="!fileFileIsRequired">
                                    <label
                                        :class="{'text-danger': states.file_link === false}"
                                        class="form-label"
                                        for="file_link"
                                    >{{ $t('projects::offers.columns.file_link').ucFirst() }}</label
                                    >
                                    <div class="input-group">
                                        <div class="input-group-text">
                                            <i class="fas fa-link"></i>
                                        </div>
                                        <input
                                            id="file_link"
                                            v-model="model.file_link"
                                            :class="{'border-danger': states.file_link === false}"
                                            :placeholder="$t('projects::offers.placeholders.file_link').ucFirst()"
                                            class="form-control"
                                            type="url"
                                        />
                                    </div>
                                    <div :class="{'d-block': states.file_link === false}" class="invalid-feedback">{{ errors.file_link }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="false" class="row mb-4">
                <div v-if="false" class="col-lg-2 col-xl-1">
                    <label
                        class="form-label"
                        for="assessed"
                    >{{ $t('projects::offers.columns.assessed').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                        <input id="assessed" v-model="model.assessed"
                               class="form-check-input float-none mx-3" type="checkbox">
                    </div>
                    <div :class="{'d-block': states.assessed === false}" class="invalid-feedback">
                        {{ errors.assessed }}
                    </div>
                </div>
                <div v-if="false" class="col-lg-2 col-xl-1">
                    <label
                        class="form-label"
                        for="accepted"
                    >{{ $t('projects::offers.columns.accepted').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                        <input id="accepted" v-model="model.accepted"
                               class="form-check-input float-none mx-3" type="checkbox">
                    </div>
                    <div :class="{'d-block': states.accepted === false}" class="invalid-feedback">
                        {{ errors.accepted }}
                    </div>
                </div>
            </div>
            <slot name="extra-fields"></slot>
            <div class="row mb-4" v-if="!loading && showed">
                <div class="col-12">
                    <billables-manager v-model="model.billables" @update:amount="(amount) => model.amount = amount"></billables-manager>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-12 text-center">
                    <template v-if="closed">
                        <button class="btn btn-outline-white" type="button" @click.prevent="unlock">
                            <i class="text-muted fas fa-lock font-size-30 cursor-pointer"></i>
                        </button>
                    </template>
                    <template v-else>
                            <button :title="btnText" class="btn btn-primary" type="submit" @click.prevent="save">
                                <i class="fas fa-save"></i>
                                <span class="ms-2 mb-4 mb-lg-0">{{ btnText }}</span>
                            </button>
                            <slot name="buttons" :save="save"></slot>
                            <button v-if="model.sent_at === null" :title="$t('base.sent').ucFirst()" class="btn btn-success ms-2" type="submit" @click.prevent="save">
                                <i class="fas fa-mail-bulk"></i>
                                <span class="ms-2 mb-4 mb-lg-0 d-none d-lg-inline">{{ $t('base.sent').ucFirst() }}</span>
                            </button>
                            <button v-if="model.id && !model.assessed" class="btn btn-white ms-2 border-secondary" type="submit" @click.prevent="answered">
                                <i id="check-icon" class="fas fa-check text-success"></i>
                                <i id="slash-icon" class="fas fa-slash text-dark"></i>
                                <i id="times-icon" class="fas fa-times text-danger"></i>
                                <span class="ms-1 mb-4 mb-lg-0 d-none d-lg-inline text-dark">{{ $t('base.answered').ucFirst() }}</span>
                            </button>
                    </template>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>
#check-icon {
    transform: scale(0.8) translate(12px, -4px);
}
#slash-icon {
    transform: rotate(100deg);
}
#times-icon {
    transform: scale(0.8) translate(-10px, 6px);
}

#offer-project i {
    transform: scale(0.9) rotate(-10deg);
    transition: 0.3s;
}

#offer-project:hover i {
    transform: scale(1);
    transition: 0.3s;
}
</style>
