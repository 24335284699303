<script>
import {mapGetters} from "vuex";

export default {
    name: "billable-edit-form",

    emits: ['update'],

    props: {
        store: {
            type: String
        },

        relations: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },

    computed: {
        ...mapGetters({
            currencies: 'currency/all'
        }),

        loading: function () {
            return this.$store.getters[`${this.store}/loading`]
        },

        states: function () {
            return this.$store.getters[`${this.store}/states`]
        },

        errors: function () {
            return this.$store.getters[`${this.store}/errors`]
        },

        item: function () {
            return this.$store.getters[`${this.store}/item`]
        }
    },

    data: function () {
        return {
            model: {},
            files: {},
        }
    },

    methods: {
        setFile: function (event, prop = 'file_file') {
            this.model[prop] = this.files[prop] = event.target.files[0];
        },

        setModel: function (model = {}) {
            const base = {
                price: model.net,
                query: { with: ['billable', ...this.relations] }
            }

            this.model = Object.assign(base, JSON.parse(JSON.stringify(model)));

            Object.keys(this.files).forEach(key => {
                this.model[key] = this.files[key];
            })
        },

        save: function () {
            this.$emit('update', this.model);
        },
    },

    watch: {
        item: {
            immediate: true,
            deep: true,
            handler: function(value) {
                this.setModel(value);
            },
        },

        quantity: function () {
            this.setModel(this.item)
        },
    },

    created() {
        this.$store.dispatch('currency/all')
    }
}
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row">
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.price === false}"
                        class="form-label"
                        for="price-input"
                    >{{ $t('projects::billables.net').ucFirst() }}</label
                    >
                    <input
                        min="0"
                        id="sort"
                        v-model="model.price"
                        :class="{'border-danger': states.price === false}"
                        :placeholder="$t('projects::billables.net').ucFirst()"
                        class="form-control"
                        type="number"
                        step="0.01"
                    />
                    <div :class="{'d-block': states.price === false}" class="invalid-feedback">{{ errors.price }}</div>
                </div>

                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.vat === false}"
                        class="form-label"
                        for="vat-input"
                    >{{ $t('projects::billables.vat').ucFirst() }}</label
                    >
                    <input
                        min="0"
                        id="sort"
                        v-model="model.vat"
                        :class="{'border-danger': states.vat === false}"
                        :placeholder="$t('projects::billables.vat').ucFirst()"
                        class="form-control"
                        type="number"
                        step="0.01"
                    />
                    <div :class="{'d-block': states.vat === false}" class="invalid-feedback">{{ errors.vat }}</div>
                </div>

                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.currency === false}"
                        class="form-label"
                        for="vat-input"
                    >{{ $t('projects::billables.currency').ucFirst() }}</label
                    >
                    <select
                        id="price-input"
                        v-model="model.currency"
                        :class="{'border-danger': states.currency === false}"
                        class="form-select"
                        name="price"
                    >
                        <option :value="model.currency === undefined ? undefined : null">
                            {{ $t('projects::billables.currency').ucFirst() }}
                        </option>
                        <option v-for="(item, index) in currencies" :key="index" :value="item.code">
                            {{ item.translation_key ? $t(item.translation_key) : item.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.vat === false}" class="invalid-feedback">{{ errors.vat }}</div>
                </div>

                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.quantity === false}"
                        class="form-label"
                        for="quantity-input"
                    >{{ $t('projects::billables.quantity').ucFirst() }}</label
                    >
                    <input
                        min="1"
                        id="sort"
                        v-model="model.quantity"
                        :class="{'border-danger': states.quantity === false}"
                        :placeholder="$t('projects::billables.quantity').ucFirst()"
                        class="form-control"
                        type="number"
                    />
                    <div :class="{'d-block': states.quantity === false}" class="invalid-feedback">{{ errors.quantity }}</div>
                </div>
                <slot name="inputs" v-bind:states="states" v-bind:errors="errors" v-bind:extra="model" v-bind:setFile="setFile"></slot>
            </div>
            <div class="row">
                <div class="col-12 mb-4 text-center">
                    <b-button variant="success" @click="save">{{ $t('base.save').ucFirst() }}</b-button>
                    <slot name="buttons"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
