<script>
import SimpleTable from "./simple-table.vue";
import TransitionToggle from "../transitions/toggle.vue";
import BillableForm from "../forms/billable-form.vue";
import BillableEditForm from "../forms/billable-edit-form.vue";
import Swal from "sweetalert2";

export default {
    name: "billable-items",

    components: {BillableEditForm, BillableForm, TransitionToggle, SimpleTable},

    props: {
        store: {
            type: String,
            required: true
        },

        id: {
            type: [Number, String],
            required: true
        },

        extraFields: {
            type: Array,
            default: () => {
                return [];
            }
        },

        relations: {
            type: Array,
            default: () => {
                return [];
            }
        },

        field: {
            type: String,
            required: true
        }
    },

    computed: {
        items: function () {
            return this.$store.getters[`${this.store}/all`]
        },

        summary: function () {
            return this.$store.getters[`${this.store}/summary`]
        },

        fields: function () {
            return [
                {
                    key: 'billable',
                    label: this.$t('projects::billables.item').ucFirst(),
                    formatter: (value) => value?.translation_key ? this.$t(value.translation_key) : value?.name
                },
                {
                    key: 'net',
                    label: this.$t('projects::billables.net').ucFirst(),
                    thClass: 'text-center min-width',
                    tdClass: 'text-end'
                },
                {
                    key: 'vat',
                    label: this.$t('projects::billables.vat').ucFirst(),
                    thClass: 'text-center min-width',
                    tdClass: 'text-end'
                },
                {
                    key: 'label',
                    label: this.$t('projects::billables.gross').ucFirst(),
                    thClass: 'text-center min-width',
                    tdClass: 'text-end'
                },
                {
                    key: 'quantity',
                    label: this.$t('projects::billables.quantity').ucFirst()
                },
                ...this.extraFields,
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: 'min-width',
                    tdClass: 'text-end'
                }
            ];
        },

        filterData: function () {
            const data = {};

            data[this.field] = this.id;

            return data;
        },
    },

    data: function () {
        return {
            showForm: false,
            pivots: []
        }
    },

    methods: {
        add: function (item) {
            this.$store.dispatch(`${this.store}/create`, item).then(() => {
                this.$store.dispatch(`${this.store}/clearItem`)
                this.$store.dispatch(`${this.store}/summary`, this.filterData);
                this.showForm = false
            })
        },

        update: function (item, callback) {
            this.$store.dispatch(`${this.store}/update`, item).then(() => {
                this.$store.dispatch(`${this.store}/clearItem`)
                this.$store.dispatch(`${this.store}/summary`, this.filterData)
                callback()
            })
        },

        editTableItem: function (item, callback) {
            this.$store.dispatch(`${this.store}/setItem`, item).then(() => {
                this.$store.dispatch(`${this.store}/summary`, this.filterData)
                callback(item)
            })
        },

        removeTableItem: function (item) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch(`${this.store}/destroy`, item.id).then(() => {
                        this.$store.dispatch(`${this.store}/summary`, this.filterData)
                    })
                }
            });
        },
    },

    watch: {
        id: {
            immediate: true,
            handler: function (id) {
                if (id) {
                    const data = {};

                    data[this.field] = id;

                    data.with = ['billable', ...this.relations];

                    this.$store.dispatch(`${this.store}/all`, data);
                }
            },
        }
    },

    unmounted() {
        this.$store.dispatch(`${this.store}/clearAll`)
    }
}
</script>

<template>
    <div class="billable-editor">
        <simple-table :items="items" :fields="fields">
            <template v-for="(field, fieldIndex) in fields" :key="fieldIndex" v-slot:[field.key]="{item, value, formatted, edit, close}">
                <slot :name="field.key" :item="item" :field="field" :value="value" :formatted="formatted" :edit="edit" :close="close">
                    <template v-if="field.key === 'actions'">
                        <span class="text-warning cursor-pointer p-2" :title="$t('base.edit').ucFirst()" @click="editTableItem(item, edit)">
                            <i class="fas fa-edit"></i>
                        </span>
                                <span class="text-danger cursor-pointer p-2" :title="$t('base.delete').ucFirst()" @click="removeTableItem(item)">
                            <i class="fas fa-trash-alt"></i>
                        </span>
                    </template>
                    <template v-else>
                        {{ field.formatter ? field.formatter(item[field.key], fieldIndex, item) : item[field.key] }}
                    </template>
                </slot>
            </template>
            <template #actions="{item, edit}">
                <span class="text-warning cursor-pointer p-2" :title="$t('base.edit').ucFirst()" @click="editTableItem(item, edit)">
                    <i class="fas fa-edit"></i>
                </span>
                <span class="text-danger cursor-pointer p-2" :title="$t('base.delete').ucFirst()" @click="removeTableItem(item)">
                    <i class="fas fa-trash-alt"></i>
                </span>
            </template>
            <template #default="{close}">
                <billable-edit-form class="border rounded px-3 pt-3 mb-4 bg-light" :store="store" :relations="relations" @update="item => update(item, close)">
                    <template #buttons>
                        <b-button class="ms-2" variant="outline-secondary" @click="close">{{ $t('base.cancel').ucFirst() }}</b-button>
                    </template>
                    <template v-if="$slots.inputs" #inputs="{states, errors, extra, setFile}">
                        <slot name="inputs" v-bind:states="states" v-bind:errors="errors" v-bind:extra="extra" v-bind:setFile="setFile"></slot>
                    </template>
                </billable-edit-form>
            </template>
            <template v-for="(field, index) in fields" :key="index" v-slot:[`foot_${field.key}`]>
                {{ summary[field.key] }}
            </template>
        </simple-table>
        <transition-toggle>
            <billable-form class="border rounded p-3 bg-white" v-if="showForm" :store="store" :owner-prop="field" :owner-id="id" @add="add">
                <template #buttons>
                    <b-button class="ms-2" variant="secondary" @click="showForm = !showForm">{{ $t('base.cancel').ucFirst() }}</b-button>
                </template>
                <template v-if="$slots.inputs" #inputs="{states, errors, extra, setFile}">
                    <slot name="inputs" v-bind:states="states" v-bind:errors="errors" v-bind:extra="extra" v-bind:setFile="setFile"></slot>
                </template>
            </billable-form>
        </transition-toggle>
        <transition-toggle>
            <div class="col-12 mb-2 text-center" v-if="!showForm">
                <b-button variant="success" @click="showForm = !showForm">{{ $t('base.add_new_item', {item: $tc('projects::offers.relationships.billables', 1)}).ucFirst() }}</b-button>
            </div>
        </transition-toggle>
        <div class="row">
            <div class="col-12">
                <p></p>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
